<template>
  <div class="component-transaction-view-transactions-table">

    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters v-show="isLoaded">
                <b-col cols="3" md="4" lg="7" xl="8">
                  <h2>{{ $t(translationPath + 'title',{start_date: startDate, end_date: endDate}) }}</h2>
                </b-col>
                <b-col cols="9" md="8" lg="5" xl="4" class="text-right">
                  <!-- Toggle columns -->
                  <b-dropdown right
                              :text="$t('common.toggle_columns')"
                              variant='outline-secondary'
                              class="float-right text-regular mr-3 toggleColumnWrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleColumns('date')" v-bind:class="{ active: aTableColumnsToDisplay.date }">{{$t(translationPath + 'columns.date')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleColumns('title')" v-bind:class="{ active: aTableColumnsToDisplay.title }">{{$t(translationPath + 'columns.title')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleColumns('amount')" v-bind:class="{ active: aTableColumnsToDisplay.amount }">{{$t(translationPath + 'columns.amount')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleColumns('subtype_description')" v-bind:class="{ active: aTableColumnsToDisplay.subtype_description }">{{$t(translationPath + 'columns.subtype_description')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleColumns('description')" v-bind:class="{ active: aTableColumnsToDisplay.description }">{{$t(translationPath + 'columns.description')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleColumns('debit_account_groups')" v-bind:class="{ active: aTableColumnsToDisplay.debit_account_groups }">{{$t(translationPath + 'columns.debit_account_groups')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleColumns('debit_accounts')" v-bind:class="{ active: aTableColumnsToDisplay.debit_accounts }">{{$t(translationPath + 'columns.debit_accounts')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleColumns('credit_account_groups')" v-bind:class="{ active: aTableColumnsToDisplay.credit_account_groups }">{{$t(translationPath + 'columns.credit_account_groups')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleColumns('credit_accounts')" v-bind:class="{ active: aTableColumnsToDisplay.credit_accounts }">{{$t(translationPath + 'columns.credit_accounts')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleColumns('active_percentage')" v-bind:class="{ active: aTableColumnsToDisplay.active_percentage }">{{$t(translationPath + 'columns.active_percentage')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleColumns('passive_percentage')" v-bind:class="{ active: aTableColumnsToDisplay.passive_percentage }">{{$t(translationPath + 'columns.passive_percentage')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleColumns('active_amount')" v-bind:class="{ active: aTableColumnsToDisplay.active_amount }">{{$t(translationPath + 'columns.active_amount')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleColumns('passive_amount')" v-bind:class="{ active: aTableColumnsToDisplay.passive_amount }">{{$t(translationPath + 'columns.passive_amount')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleColumns('import')" v-bind:class="{ active: aTableColumnsToDisplay.import }">{{$t(translationPath + 'columns.import')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleColumns('checkbox')" v-bind:class="{ active: aTableColumnsToDisplay.checkbox }">{{$t(translationPath + 'columns.checkbox')}}</b-dropdown-item>
                  </b-dropdown>
                  <!-- END toggle columns -->

                  <b-button variant='outline-secondary' v-model="bShowQuickFilter" class="float-right text-red mr-3 toggleColumnWrapper" size="sm" @click="toggleQuickFilters">{{$t(translationPath + 'toggle_quick_filters')}}</b-button>

                </b-col>
              </b-row>
            </template>

            <div class="col-lg-8 pl-0" v-html="$t(translationPath + 'description')"></div>

            <b-row no-gutters>
              <b-col cols="12" lg="9">
                <quick-filter
                  :show-dropdowns="bShowQuickFilter"
                  :filters="aQuickFilter"
                  :account-data="aAccountData"
                  @clear="emitBadgeClick"
                  @update="addQuickFilter"
                />
              </b-col>
              <b-col cols="12" lg="3">
                <!-- Recategorize -->
                <b-button variant="outline-secondary" class="btn-sm float-right mb-1" @click="redirectToRecategorized" v-if="aSelectedRows.length">
                  <i class="flaticon stroke menu-1 mr-2"></i> {{ $t(translationPath + "buttons.mass_recategorize") }}
                </b-button>

                <!-- Delete -->
                <b-button variant="outline-secondary" class="btn-sm mr-2 float-right mb-1" @click="$refs.DeleteTransactionsModal.show()" v-if="aSelectedRows.length">
                  <i class="flaticon stroke trash-3 mr-2"></i>{{ $t(translationPath + "buttons.mass_delete") }}
                </b-button>
              </b-col>
            </b-row>

            <!-- TRANSACTION TABLE -->
            <b-table
              class="spirecta-simple-table transaction-table mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="tableData"
              :fields="aTableColumns"
              :busy="!isLoaded"
              :filter="sTableFilter"
              :filter-included-fields="aColumnsToFilterOn"
              :tbodyTrClass="tableRowSetCssClass"
              :tbodyTrAttr="tableRowSetHtmlAttribs"
              ref="oTransactionTable"
              @filtered="onFilteredTransactions"
              @row-selected="onRowSelected"
              @sort-changed="removeMonthHeadingRows"
              :emptyFilteredText ="$t('common.no_filter_result')"
              :emptyText="$t(translationPath + 'misc.no_data')"
              :per-page="iPaginationPerPage"
              :current-page="iPaginationCurrentPage"
            >
              <!-- LOADER -->
              <template v-slot:table-busy><loader/></template>

              <!-- FILTER -->
              <template slot="top-row">
                <td :colspan="this.aTableColumns.length">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-search text-secondary"></i>
                    <b-form-input v-model="sTableFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                  </div>
                </td>
              </template>

              <!-- CUSTOM SLOTS -->

              <!-- HEAD :: CHECKBOX ALL -->
              <template v-slot:head(checkbox)>
                <b-form-checkbox v-model="bAreAllRowsSelected" @change="toggleAllCheckBoxRows"></b-form-checkbox>
              </template>

              <!-- date slot-->
              <template v-slot:cell(date)="row">
                  <span class="text-nowrap">{{row.value}}</span>
              </template>

              <!-- title slot-->
              <template v-slot:cell(title)="row">
                <template v-if="row.item.row_type==='transaction'">
                  <b-link :href="'/transactions/view/transaction/'+row.item.id" @click.prevent="navigateToTransaction(row.item.id)">{{row.value}}</b-link>
                </template>
              </template>

              <!-- amount slot-->
              <template v-slot:cell(amount)="row">
                <template v-if="row.item.row_type==='transaction'">
                  <span :class="'transaction_' + row.item.transaction_subtype" class="text-nowrap">{{row.value}}</span>
                </template>
              </template>

              <!-- subtype_description slot-->
              <template v-slot:cell(subtype_description)="row">
                <template v-if="row.item.row_type==='transaction'">
                  <i18n :path="'common.transaction_subtypes.' +row.item.transaction_subtype" tag="span" class="text-gray" style="font-size: 90%">
                    <template v-slot:cr_account v-if="Object.prototype.hasOwnProperty.call(row.item,'cr_entry') && row.item.cr_entry">
                      <b-link :to="'/reports/performance/accounts/' +row.item.cr_entry.account_id +'/view'" class="subtype_description_link">{{ currentCOA.prefix_account_title_with_code ? row.item.cr_entry.account_code + ' - ' + row.item.cr_entry.account_title : row.item.cr_entry.account_title }}</b-link>
                    </template>
                    <template v-slot:dr_account v-if="Object.prototype.hasOwnProperty.call(row.item,'dr_entry') && row.item.dr_entry">
                      <b-link :to="'/reports/performance/accounts/' +row.item.dr_entry.account_id +'/view'" class="subtype_description_link">{{ currentCOA.prefix_account_title_with_code ? row.item.dr_entry.account_code + ' - ' + row.item.dr_entry.account_title : row.item.dr_entry.account_title }}</b-link>
                    </template>
                  </i18n>
                </template>
              </template>

              <!-- credit_accounts -->
              <template v-slot:cell(credit_accounts)="row">
                <template v-if="row.item.row_type === 'transaction'">
                  <template v-for="(oCrAccount, index) in row.item.credit_accounts">
                    <b-link :to="'/reports/performance/accounts/'+oCrAccount.account_id+'/view'" v-bind:key="index">{{ (currentCOA.prefix_account_title_with_code ? oCrAccount.account_code + ' - ' : '' ) + oCrAccount.account_title + ( row.item.credit_accounts.length > 1 ? ', ' : '' ) }}</b-link>
                  </template>
                </template>
              </template>

              <!-- debit_accounts -->
              <template v-slot:cell(debit_accounts)="row">
                <template v-if="row.item.row_type === 'transaction'">
                  <template v-for="(oDrAccount, index) in row.item.debit_accounts">
                    <b-link :to="'/reports/performance/accounts/'+oDrAccount.account_id+'/view'" v-bind:key="index">{{ (currentCOA.prefix_account_title_with_code ? oDrAccount.account_code + ' - ' : '' ) + oDrAccount.account_title + ( row.item.debit_accounts.length > 1 ? ', ' : '' ) }}</b-link>
                  </template>
                </template>
              </template>

              <!-- credit_account_groups -->
              <template v-slot:cell(credit_account_groups)="row">
                <template v-if="row.item.row_type === 'transaction'">
                  <template v-for="(oCrAccount, index) in row.item.credit_accounts">
                    <b-link :to="'/reports/performance/accounts/'+oCrAccount.account_group_id+'/view'" v-bind:key="index">{{ (currentCOA.prefix_account_title_with_code ? oCrAccount.account_group_code + ' - ' : '' ) + oCrAccount.account_group_title + ( row.item.debit_accounts.length > 1 ? ', ' : '' ) }}</b-link>
                  </template>
                </template>
              </template>

              <!-- debit_account_groups -->
              <template v-slot:cell(debit_account_groups)="row">
                <template v-if="row.item.row_type === 'transaction'">
                  <template v-for="(oDrAccount, index) in row.item.debit_accounts">
                    <b-link :to="'/reports/performance/accounts/'+oDrAccount.account_group_id+'/view'" v-bind:key="index">{{ (currentCOA.prefix_account_title_with_code ? oDrAccount.account_group_code + ' - ' : '' ) + oDrAccount.account_group_title + ( row.item.debit_accounts.length > 1 ? ', ' : '' ) }}</b-link>
                  </template>
                </template>
              </template>

              <!-- import -->
              <template v-slot:cell(import)="row">
                <template v-if="row.item.row_type === 'transaction'">
                  <b-link :to="'/transactions/view/transactions?=' + createParamStringFromFilterParams(aFilterParams, 'import_id',row.item.import_id)">{{row.value}}</b-link>
                </template>
              </template>

              <!-- active_percentage slot-->
              <template v-slot:cell(active_percentage)="row">
                <template v-if="row.item.row_type==='transaction'">{{row.value}}</template>
              </template>

              <!-- active_amount slot-->
              <template v-slot:cell(active_amount)="row">
                <template v-if="row.item.row_type==='transaction'">{{row.value}}</template>
              </template>

              <!-- passive_percentage slot-->
              <template v-slot:cell(passive_percentage)="row">
                <template v-if="row.item.row_type==='transaction'">{{row.value}}</template>
              </template>

              <!-- passive_amount slot-->
              <template v-slot:cell(passive_amount)="row">
                <template v-if="row.item.row_type==='transaction'">{{row.value}}</template>
              </template>

              <!-- CHECKBOX -->
              <template v-slot:cell(checkbox)="row">
                <template v-if="row.item.row_type === 'transaction'">
                  <b-form-checkbox-group v-model="aSelectedRows" v-if="row.item.row_type==='transaction'">
                    <b-form-checkbox :value="row.item" @change="(values) => toggleCheckBoxRow(values, row.index)"></b-form-checkbox>
                  </b-form-checkbox-group>
                </template>
              </template>

              <template v-slot:custom-foot>
                <b-tr class="tr-total">
                  <b-td :colspan="aTableColumns.length !== 0 ? aTableColumns.length : 2">
                    <table class="foot_row_summary_table">
                      <tr v-if="oTotals.income">
                        <td>{{$t(translationPath + 'misc.sum_income' )}}</td>
                        <td class="text-right">{{formatAmt(oTotals.income)}}</td>
                      </tr>
                      <tr v-if="oTotals.expense">
                        <td>{{$t(translationPath + 'misc.sum_expense' )}}</td>
                        <td class="text-right">{{formatAmt(oTotals.expense)}}</td>
                      </tr>
                      <tr v-if="oTotals.other">
                        <td>{{$t(translationPath + 'misc.sum_other' )}}</td>
                        <td class="text-right">{{formatAmt(oTotals.other)}}</td>
                      </tr>
                      <tr>
                        <td>{{$t(translationPath + 'misc.sum_transactions' )}}</td>
                        <td class="text-right">{{oTotals.count}} (<b-link href="#" @click="exportTransactions()"  class="text-blue">{{$t(translationPath + 'misc.export_transactions' )}}</b-link>)</td>
                      </tr>
                    </table>
                  </b-td>
                </b-tr>
              </template>
              <!-- END CUSTOM SLOTS -->
            </b-table>

            <!-- PAGINATION -->
            <div class="pagination-wrapper">
              <div class="d-flex  flex-column flex-md-row justify-content-between">
                <div class="d-flex flex-column flex-md-row mb-3 mb-md-0">
                  <div v-if="iPaginationTotalRows > 0" class="d-flex align-items-center justify-content-center">
                    <span class="text-gray">{{ $t('common.pagination.showing')}} {{(iPaginationCurrentPage - 1) * iPaginationPerPage + 1}} {{ $t('common.pagination.to')}} {{ paginationToNumber() }} {{ $t('common.pagination.of')}} {{ tableData.length }}</span>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-center pagination-pages-wrapper">
                  <b-pagination v-if="iPaginationTotalRows > 0" :total-rows="tableData.length" :per-page="iPaginationPerPage" :prev-text="$t('common.pagination.prev')" :next-text="$t('common.pagination.next')" v-model="iPaginationCurrentPage" class="my-0"/>
                </div>
              </div>
            </div>
            <!-- END PAGINATION -->

          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <delete-modal
      ref="DeleteTransactionsModal"
      :heading="$t(translationPath + 'delete_modal.heading' + ( aSelectedRows.length === 1 ? '_singular' : '_plural')  , {count: aSelectedRows.length}).toString()"
      :message="$t(translationPath + 'delete_modal.text' + ( aSelectedRows.length === 1 ? '_singular' : '_plural')  , {count: aSelectedRows.length}).toString()"
      @on-delete-confirm="deleteSelectedTransactions"
    />
  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapState } from 'vuex'
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'
import DeleteModal from '@/components/modals/DeleteModal'
import axios from 'axios'
import TransactionsViewMixin from './../inc/TransactionsViewMixin'
import QuickFilter from './../inc/QuickFilter'

export default {
  name: 'TransactionsViewTransactionsTable',
  mixins: [TransactionsViewMixin],
  components: {
    Loader,
    DeleteModal,
    QuickFilter
  },
  filters: {
    formatAmount
  },
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    isLoaded: { type: Boolean, default: false },
    activeTab: { type: String, default: '' },
    paginationCurrentPage: { type: String, default: '1' },
    aFilterParams: {
      type: Object,
      default: function () {
        return {}
      }
    },
    options: {
      type: Object,
      default: function () {
        return {}
      }
    },
    apiData: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  data () {
    return {
      translationPath: 'transactions.view-v2.table.',
      // Data for transaction table
      tableData: [],
      // All accounts for quickfilter account_id
      aAccountData: {
        incomeAccounts: null,
        incomeGroups: null,
        expenseGroups: null,
        expenseAccounts: null,
        allAccounts: null,
        allAccountGroups: null
      },
      bShowQuickFilter: false,
      loadingAccounts: false,
      /**
       * The object we use to create filter that we emit to
       * parent to change prop aFilterParams
       */
      aQuickFilter: {
        title: null,
        transaction_subtype: null,
        amount_min: null,
        amount_max: null,
        start_date: null,
        end_date: null,
        import_id: null,
        account_id: null,
        source_account_id: null,
        account_group_id: null,
        description: null
      },
      sTableFilter: '',
      isTableSorted: false,
      aColumnsToFilterOn: ['title'],
      aTableColumns: [],
      aTableColumnsToDisplay: {
        date: true,
        title: true,
        amount: true,
        subtype_description: true,
        import: false,
        description: false,
        checkbox: true,
        active_percentage: false,
        passive_percentage: false,
        active_amount: false,
        passive_amount: false,
        credit_accounts: false,
        debit_accounts: false,
        credit_account_groups: false,
        debit_account_groups: false
      },
      aSelectedRows: [],
      aProtectedRows: [],
      bAreAllRowsSelected: false,
      iPaginationPerPage: 50,
      iPaginationCurrentPage: null,
      iPaginationNoOfMonthRows: 0,
      iPaginationTotalRows: 0,
      oTotals: {
        income: 0,
        expense: 0,
        other: 0,
        count: 0
      },
      bPaginationFirstLoad: true,
      accounts: []
    }
  },
  beforeMount () {
    this.iPaginationCurrentPage = this.paginationCurrentPage
  },
  mounted () {
    if (this.currentCOA.locale) {
      this.iPaginationPerPage = this.currentCOA.default_pagination_rows
    }
    this.loadAccountOptions()
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA'])
  },
  methods: {
    loadAccountOptions () {
      if (this.bAccountsLoaded) {
        return true
      }
      this.aSyncloadAccountOptions()
    },
    /**
     * Load accounts for quickfilter account_id
     */
    async aSyncloadAccountOptions () {
      this.loadingAccounts = true

      try {
        const sApiUrl = `${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=income,expense,asset,liability&include_networth_account=1`
        const response = await axios.get(sApiUrl)

        const aAccountsApiData = response.data.data

        this.aAccountData.incomeGroups = aAccountsApiData.filter(item => item.type === 'income')
        this.aAccountData.incomeAccounts = this.aAccountData.incomeGroups.map(item => item.accounts).reduce(function (aTmp, aAccount) { return aTmp.concat(aAccount) })

        this.aAccountData.expenseGroups = aAccountsApiData.filter(item => item.type === 'expense')
        this.aAccountData.expenseAccounts = this.aAccountData.expenseGroups.map(item => item.accounts).reduce(function (aTmp, aAccount) { return aTmp.concat(aAccount) })

        this.aAccountData.assetGroups = aAccountsApiData.filter(item => item.type === 'asset')
        this.aAccountData.liabilityGroups = aAccountsApiData.filter(item => item.type === 'liability')

        // Create an array where keys are account_ids and values are labels
        this.aAccountData.allAccounts = aAccountsApiData.map(item => item.accounts).reduce(function (aTmp, aAccount) { return aTmp.concat(aAccount) })
        this.aAccountData.allAccounts = this.aAccountData.allAccounts.map(function (item) { return { id: item.id, label: item.label } })

        this.aAccountData.allAccountGroups = aAccountsApiData.map(function (item) { return { id: item.id, label: item.label } })

        this.loadingAccounts = false
      } catch (error) {
        console.error(error)
        return []
      }
    },
    buildTableColumns () {
      this.aColumnsToFilterOn = []
      this.aTableColumns = []
      let sColumnName = null
      let sAlignCssClass = null
      let oFormatterFunction = null

      const aAvailableColumnKeys = [
        'date', 'title', 'amount', 'subtype_description', 'description', 'debit_account_groups', 'debit_accounts',
        'credit_account_groups', 'credit_accounts', 'active_percentage', 'passive_percentage', 'active_amount', 'active_amount', 'passive_amount', 'import'
      ]

      // Loop through all available columns in the order in the array and see which should be displayed / added
      for (const idx in aAvailableColumnKeys) {
        sColumnName = aAvailableColumnKeys[idx]

        if (this.aTableColumnsToDisplay[sColumnName]) {
          sAlignCssClass = 'text-left'
          oFormatterFunction = null

          switch (sColumnName) {
            case 'amount':
            case 'active_amount' :
            case 'passive_amount' :
              sAlignCssClass = 'text-right'
              oFormatterFunction = this.formatAmt
              break
            case 'active_percentage' :
            case 'passive_percentage' :
              sAlignCssClass = 'text-right'
              oFormatterFunction = this.formatPercentage
              break
          }

          // Add table column
          this.aTableColumns.push({
            key: sColumnName,
            label: this.$t(this.translationPath + 'columns.' + sColumnName),
            sortable: true,
            class: sAlignCssClass + ' td-' + sColumnName,
            formatter: oFormatterFunction
          })
          this.aColumnsToFilterOn.push(sColumnName)
        }
      }

      // Handle separately
      if (this.aTableColumnsToDisplay.checkbox) {
        this.aTableColumns.push({ key: 'checkbox', label: '', sortable: false, class: 'text-right td-checkbox' })
      }

      /* process.nextTick(() => {
        this.replaceMonthRowHtml()
      }) */

      this.iPaginationTotalRows = this.oTotals.count
      return this.aTableColumns
    },
    /**
     * Shorthand for formatAmount
     */
    formatAmt (fAmount) {
      if (this.currentCOA.locale && fAmount !== '') {
        return formatAmount(fAmount, this.currentCOA.locale, this.currentCOA.currency, this.currentCOA.currency_iso, true, 2)
      }
      return fAmount
    },
    formatPercentage (fPercentage) {
      return fPercentage !== '' ? fPercentage + ' %' : ''
    },
    /**
     * Before emitting to parent, fix quick_filter params
     */
    addQuickFilter (data) {
      this.aQuickFilter = data
      this.$emit('emitQuickFilterUpdate', this.aQuickFilter)
    },
    emitBadgeClick (data) {
      this.$emit('emitBadgeClick', data)
    },
    toggleColumns (sColumnName) {
      this.aTableColumnsToDisplay[sColumnName] = !this.aTableColumnsToDisplay[sColumnName]
      this.buildTableColumns()
    },
    navigateToTransaction (id) {
      const params = Object.assign({}, {
        transaction_id: id,
        back_url: '/transactions/view/?start_date=' + this.aQuickFilter.start_date + '&end_date=' + this.aQuickFilter.end_date,
        back_url_title: this.$t(this.translationPath + 'redirect_back_to')
      })
      this.$router.push({ name: 'transactions-create-single', params })
    },
    tableRowSetCssClass (oTableRow) {
      if (oTableRow === null) { return '' }

      switch (oTableRow.row_type) {
        case 'transaction' :
          return 'tr-transaction'
        case 'month' :
          return 'tr-month'
        case 'total' :
          return 'tr-total'
      }
    },
    tableRowSetHtmlAttribs (oTableRow) {
      if (oTableRow === null) { return '' }

      switch (oTableRow.row_type) {
        case 'month' :
          return { title_string: oTableRow.date, year: oTableRow.year, month: oTableRow.month }
      }
    },
    // Calculate X for label "showing 1 to X"
    paginationToNumber () {
      let iToNumber = this.iPaginationCurrentPage * this.iPaginationPerPage
      if (iToNumber > this.tableData.length) {
        iToNumber = this.tableData.length
      }
      return iToNumber
    },
    exportTransactions () {
      let sData = 'Date;Title;Amount;TransactionType;CreditAccount;DebitAccount' + '\n'
      let t = null

      for (const idx in this.apiData.transactions) {
        t = this.apiData.transactions[idx]
        sData += t.date + ';"' + t.title + '";' + t.amount + ';' + t.transaction_subtype + ';"' + t.cr_entries[0].account_title + '";"' + t.dr_entries[0].account_title + '";' + '\n'
      }

      const url = window.URL.createObjectURL(new Blob(['\ufeff', sData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'spirecta-transactions.csv')
      document.body.appendChild(link)
      link.click()
    },
    buildTableDataRows () {
      if (!this.isLoaded) { return [] }
      this.buildTableColumns()

      // Prep
      this.tableData = []
      let oTransaction = null
      let oTmpRow = null
      let sYearMonth = null
      let sPrevYearMonth = ''
      this.iPaginationNoOfMonthRows = 0
      this.oTotals = {
        income: 0,
        expense: 0,
        other: 0,
        count: 0
      }

      // Loop through transactions
      for (const idx in this.apiData.transactions) {
        oTransaction = this.apiData.transactions[idx]

        sYearMonth = new Date(oTransaction.date).getFullYear() + '-' + new Date(oTransaction.date).getMonth()
        if (sPrevYearMonth !== sYearMonth) {
          oTmpRow = {
            row_type: 'month',
            date: this.$t(this.translationPath + 'months.month_' + (parseInt(new Date(oTransaction.date).getMonth()) + 1)) + ', ' + new Date(oTransaction.date).getFullYear(),
            title: '',
            year: new Date(oTransaction.date).getFullYear(),
            month: new Date(oTransaction.date).getMonth() + 1
          }
          sPrevYearMonth = sYearMonth
          this.iPaginationNoOfMonthRows++
          this.tableData.push(oTmpRow)
        }

        oTmpRow = {
          id: oTransaction.id,
          date: oTransaction.date,
          title: oTransaction.title,
          amount: oTransaction.amount,
          amount_raw: oTransaction.amount,
          transaction_subtype: oTransaction.transaction_subtype,
          row_type: 'transaction',
          dr_entry: oTransaction.dr_entries[0],
          cr_entry: oTransaction.cr_entries[0],
          description: oTransaction.description,
          import: oTransaction.import_title,
          import_id: oTransaction.import_id,
          passive_amount: oTransaction.active_percentage !== null ? oTransaction.passive_amount : '',
          active_amount: oTransaction.active_percentage !== null ? oTransaction.active_amount : '',
          active_percentage: oTransaction.active_percentage !== null ? oTransaction.active_percentage : '',
          passive_percentage: oTransaction.active_percentage !== null ? (100 - oTransaction.active_percentage) : '',
          debit_accounts: oTransaction.dr_entries,
          credit_accounts: oTransaction.cr_entries,
          tags: oTransaction.tags
        }

        switch (oTransaction.transaction_subtype) {
          case 'expense_debit' :
          case 'expense_credit' :
          case 'expense_networth' :
            this.oTotals.expense += oTransaction.amount
            break
          case 'income_networth' :
          case 'income' :
          case 'income_to_liability' :
          case 'income_asset_value_increase_capital_account' :
            this.oTotals.income += oTransaction.amount
            break
          default:
            this.oTotals.other += oTransaction.amount
            break
        }

        this.oTotals.count++
        this.tableData.push(oTmpRow)
      }
      this.iPaginationTotalRows = this.oTotals.count
      if (this.isTableSorted) {
        this.removeMonthHeadingRows()
      }

      // console.log(this.iPaginationPerPage, this.iPaginationNoOfMonthRows, this.iPaginationPerPage + this.iPaginationNoOfMonthRows)
      return this.tableData
    },
    /* Toggle related methods */
    onFilteredTransactions (filteredItems) {
      const filteredIndex = filteredItems.map((item) => item.index)
      this.aProtectedRows = this.tableData.map((item) => item.index).filter((item) => filteredIndex.indexOf(item) === -1)
      this.iPaginationTotalRows = filteredItems.filter((item) => (item.row_type === 'transaction')).length

      if (this.iPaginationCurrentPage !== '1') {
        this.iPaginationCurrentPage = 1
      }
    },
    onRowSelected (item) {
      if (item.row_type === 'transaction') {
        this.aSelectedRows = item
      }
    },
    toggleAllCheckBoxRows (checked) {
      const transactionsItems = this.$refs.oTransactionTable.filteredItems

      if (checked) {
        transactionsItems.forEach((elem) => {
          if (elem.row_type === 'transaction') {
            const selectedIndex = [...this.aSelectedRows].map((valueItem) => valueItem.index)

            if (selectedIndex.indexOf(elem.index) === -1 && this.aProtectedRows.indexOf(elem.index) === -1) {
              this.$nextTick(function () {
                this.aSelectedRows.push(elem)
              })
            }
          }
        })
      } else {
        this.aSelectedRows = []
      }
    },
    toggleCheckBoxRow (value, index) {
      if (value) {
        this.$refs.oTransactionTable.selectRow(index)
      } else {
        this.$refs.oTransactionTable.unselectRow(index)
      }
    },
    toggleQuickFilters () {
      this.bShowQuickFilter = !this.bShowQuickFilter
    },
    deleteSelectedTransactions () {
      const data = { transaction_ids: [] }
      this.aSelectedRows.map(row => {
        data.transaction_ids.push(row.id)
      })
      axios.delete(`${process.env.VUE_APP_ROOT_API}/transactions/with/entries`, { data: data })
        .then(response => {
          this.aSelectedRows = []
          this.$bvToast.toast(this.$t(this.translationPath + 'toasts.delete_text_success').toString(), {
            title: this.$t(this.translationPath + 'toasts.delete_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.$emit('change')
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'toasts.delete_text_fail').toString(), {
            title: this.$t(this.translationPath + 'toasts.delete_title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    },
    removeMonthHeadingRows () {
      this.isTableSorted = true
      this.tableData = this.tableData.filter(row => row.row_type === 'transaction')
    },
    redirectToRecategorized () {
      this.$router.push({
        name: 'transactions-edit-multiple-wizard',
        params: {
          transactions: this.aSelectedRows
        }
      })
    }
  },
  watch: {
    apiData: {
      handler: function () {
        this.buildTableDataRows()
        this.aQuickFilter = { ...this.aFilterParams }
      },
      deep: true,
      immediate: true
    },
    isLoaded: {
      handler: 'buildTableDataRows',
      deep: true,
      immediate: true
    },
    activeTab: {
      handler: 'buildTableDataRows',
      deep: true,
      immediate: true
    },
    iPaginationCurrentPage: {
      handler: function () {
        const oQuery = { ...this.$route.query }
        if (oQuery.current_page !== this.iPaginationCurrentPage && this.iPaginationCurrentPage !== '1') {
          oQuery.current_page = this.iPaginationCurrentPage
          this.$router.push({ name: this.$route.name, query: oQuery })
        }
      }
    },
    /**
     * currentCOA can be loaded with delay. We need to rebuild table rows after COA is loaded
     * in order to show amounts in correct format.
     */
    'currentCOA.locale' () {
      this.iPaginationPerPage = this.currentCOA.default_pagination_rows
      this.buildTableDataRows()
    }
  }
}
</script>

<style lang="scss">
.component-transaction-view-transactions-table{

  .badge-close{
    font-size: 110%;
    font-weight: 900;
    color:black;
  }
  .transaction-table{

    .subtype_description_link{
      color:#84888F;
      text-decoration: underline;
    }
    .subtype_description_link:hover{
      color:#d38786;
    }

    td:nth-child(1){
      font-weight: 400;
    }

    // TR:s
    .tr-month td{
      padding-top:20px;
      background: #FFF;
      font-weight: 800;
    }

    .tr-total td{
      background: #FFF;
      border-top:1px solid black;
      border-bottom:1px solid black;
      font-size:90%;
    }

    // Stacked table
    @media screen and (max-width: 767px) {
      .tr-month td:not(.td-date){display: none;}
      .td-amount{text-align: left!important;}
      .td-checkbox{text-align: center!important;}
    }

    // Full table
    @media screen and (min-width: 767px) {
      .td-date{width:130px;min-width: 130px;max-width: 130px;}
      .td-checkbox{width:50px;min-width: 50px;max-width: 50px;padding:0;text-align: right;}
    }
  }

  .foot_row_summary_table{
    td{
      border:0!important;
      padding:0!important;
    }
    td:nth-child(2){
      padding-left:50px!important;
    }
  }
}
</style>
