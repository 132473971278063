<template>
  <div class="component-transaction-view-predefined-selection-potential-duplicate-transfers-table">

    <b-row no-gutters>
      <div class="col-md-8" v-html="$t(translationPath + 'description2', { period_start: startDate, period_end: endDate})"></div>
    </b-row>

    <b-alert show v-if="busyState === false && Object.keys(potential_duplicate_transfer_pairs).length == 0" variant="success">
      <div v-html="$t(translationPath + 'no_potential_transfer_duplicates_found', { period_start: startDate, period_end: endDate})"></div>
    </b-alert>

    <b-table-simple hover striped v-if="Object.keys(potential_duplicate_transfer_pairs).length !== 0 && busyState === false">
      <b-thead>
        <b-tr>
          <b-th></b-th>
          <b-th colspan="4">{{ $t(translationPath + 'transaction') }}</b-th>
          <b-th colspan="4">{{ $t(translationPath + 'potential_transfer_duplicate') }}</b-th>
        </b-tr>
        <b-tr>
          <b-th></b-th>
          <b-th>{{ $t('common.date') }}</b-th>
          <b-th>{{ $t('common.title') }}</b-th>
          <b-th>{{ $t('common.amount') }}</b-th>
          <b-th style="width:20%">{{ $t('common.description') }}</b-th>
          <b-th>{{ $t('common.date') }}</b-th>
          <b-th>{{ $t('common.title') }}</b-th>
          <b-th>{{ $t('common.amount') }}</b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(item,key1) in potential_duplicate_transfer_pairs">
          <b-tr :key="key1">
            <b-td>
              <button class="btn plain-btn text-regular action-button" @click="onDeleteTransferById({ id: item[0].id, title: item[0].title })"><i class="flaticon solid trash-3 text-secondary"></i></button>
            </b-td>
            <b-td><span class="text-nowrap">{{item[0].date}}</span></b-td>
            <b-td><b-link :to="`/transactions/view/transaction/`+item[0].id" class="text-red">{{item[0].title}}</b-link></b-td>
            <b-td>{{item[0].amount}}</b-td>
            <b-td>
              <i18n :path="'common.transaction_subtypes.'+item[0].transaction_subtype" tag="span" style="font-size:90%">
                <template v-slot:cr_account>
                  <b-link :to="'/reports/performance/accounts/'+item[0].cr_entries[0].account_id+'/view'" class="text-red">{{ item[0].cr_entries[0].account_title }}</b-link>
                </template>
                <template v-slot:dr_account>
                  <b-link :to="'/reports/performance/accounts/'+item[0].dr_entries[0].account_id+'/view'" class="text-red">{{ item[0].dr_entries[0].account_title }}</b-link>
                </template>
              </i18n>
            </b-td>
            <b-td><span class="text-nowrap">{{item[1].date}}</span></b-td>
            <b-td><b-link :to="`/transactions/view/transaction/`+item[1].id" class="text-red">{{item[1].title}}</b-link></b-td>
            <b-td>{{item[1].amount}}</b-td>
            <b-td>
              <button class="btn plain-btn text-regular action-button" @click="onDeleteTransferById({ id: item[1].id, title: item[1].title })"><i class="flaticon solid trash-3 text-secondary"></i></button>
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>

    <delete-modal
      ref="deleteTransactionModal"
      :heading="deleteModalMessage"
      :message="$t(`${translationPath}delete_transaction_message`)"
      @on-delete-cancel="resetDelete"
      @on-delete-confirm="confirmTransactionDelete"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import formatAmount from '@/assets/filters/formatAmount'
import DeleteModal from '@/components/modals/DeleteModal'

export default {
  name: 'TransactionsViewPredefinedSelectionsPotentialDuplicateTransfersTable',
  filters: { formatAmount },
  components: {
    DeleteModal
  },
  props: ['startDate', 'endDate'],
  data () {
    return {
      translationPath: 'transactions.view-v2.predefined_selections.duplicate_transfers.',
      transfers: [],
      potential_duplicate_transfer_pairs: [],
      delete_transaction: null,
      loadedStartDate: null,
      loadedEndDate: null
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    deleteModalMessage () {
      if (!this.delete_transaction) return ''
      const transactionName = { name: this.delete_transaction.title }
      return this.$t(`${this.translationPath}delete_transaction`, transactionName)
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    deleteTransaction (id) {
      return axios.delete(`${process.env.VUE_APP_ROOT_API}/transactions/${id}`)
        .then(data => {
          this.$root.$bvToast.toast(this.$t(this.translationPath + 'toast.delete.success_message').toString(), {
            title: this.$t(this.translationPath + 'toast.delete.heading').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          for (const i in this.potential_duplicate_transfer_pairs) {
            for (const j in this.potential_duplicate_transfer_pairs[i]) {
              if (this.potential_duplicate_transfer_pairs[i][j].id === id) {
                delete this.potential_duplicate_transfer_pairs[i]
                break
              }
            }
          }
          return true
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.delete.failed_message').toString(), {
            title: this.$t(this.translationPath + 'toast.delete.heading').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
          return true
        })
    },
    async confirmTransactionDelete () {
      if (this.delete_transaction) {
        await this.deleteTransaction(this.delete_transaction.id)
      }
      this.$refs.deleteTransactionModal.hide()
      this.resetDelete()
    },
    resetDelete () {
      this.delete_transaction = null
    },
    onDeleteTransferById ({ title, id }) {
      this.delete_transaction = {
        title,
        id
      }
      this.$refs.deleteTransactionModal.show()
    },
    loadData () {
      this.busyState = true

      if (this.startDate !== this.loadedStartDate || this.endDate !== this.loadedEndDate) {
        let sParams = ''
        if (this.startDate !== null) { sParams += '&start_date=' + this.startDate }
        if (this.endDate !== null) { sParams += '&end_date=' + this.endDate }

        axios.get(`${process.env.VUE_APP_ROOT_API}/transactions/duplicates/transfers?${sParams}`)
          .then(response => {
            this.busyState = false
            this.transfers = response.data.data[0].transfer_transactions
            this.potential_duplicate_transfer_pairs = response.data.data[0].potential_duplicate_transfer_pairs
          })
          .catch(err => {
            this.busyState = false
            console.error(err)
          })
        this.loadedStartDate = this.startDate
        this.loadedEndDate = this.endDate
      }
    }
  },
  watch: {
    startDate: {
      handler () {
        this.loadData()
      },
      immediate: true,
      deep: true
    },
    endDate: {
      handler () {
        this.loadData()
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
.component-transaction-view-predefined-selection-potential-duplicate-transfers-table {
}
</style>
