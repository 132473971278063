<template>
  <div class="component-transaction-view-predefined-selections-imports-table">

    <b-table class="spirecta-simple-table mb-0" show-empty hover responsive striped stacked="sm"
             :items="aTableData"
             :fields="aTableColumns"
             :busy="!bImportsLoaded"
             :filter="sTableFilter"
             :filter-included-fields="['title']"
    >
      <!-- FILTER -->
      <template slot="top-row">
        <td :colspan="(aTableColumns.length)">
          <div class="d-flex align-items-center">
            <i class="fa fa-search text-secondary"></i>
            <b-form-input v-model="sTableFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
          </div>
        </td>
      </template>

      <!-- title -->
      <template v-slot:cell(title)="row">
        <b-link @click="createToLink(row.item.id)">{{row.value}}</b-link>
      </template>
    </b-table>

  </div>
</template>

<script>
import TansactionsViewMixin from './../../inc/TransactionsViewMixin'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'TransactionsViewPredefinedSelectionsImportsTable',
  mixins: [TansactionsViewMixin],
  components: {
  },
  filters: {
  },
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      translationPath: 'transactions.view-v2.predefined_selections.imports.',
      sTableFilter: '',
      aColumnsToFilterOn: ['title', 'account_title'],
      aTableData: [],
      aTableColumns: [],
      bImportsLoaded: false,
      aCompletedImportsData: null,
      loadedStartDate: null,
      loadedEndDate: null
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA'])
  },
  methods: {
    async loadData () {
      this.bImportsLoaded = false

      if (this.startDate !== this.loadedStartDate || this.endDate !== this.loadedEndDate) {
        this.loadedStartDate = this.startDate
        this.loadedEndDate = this.endDate

        let sParams = ''
        if (this.startDate !== null) { sParams += '&start_date=' + this.startDate }
        if (this.endDate !== null) { sParams += '&end_date=' + this.endDate }

        const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/bankstatements/imports/completed?' + sParams

        await axios({ method: 'get', url: sApiEndpoint })
          .then((response) => {
            this.aCompletedImportsData = response.data.data.imports
            this.bImportsLoaded = true

            this.buildTableDataRows()
            return true
          })
          .catch((error) => {
            console.error(error)
            return false
          })
      }
    },
    createToLink (iImportId) {
      this.$router.push({
        params: { tab: 'transactions' },
        query: { start_date: null, end_date: null, import_id: iImportId }
      })
    },
    buildTableDataRows () {
      if (!this.bImportsLoaded) {
        return []
      }
      this.aTableData = []

      for (const idx in this.aCompletedImportsData) {
        const oImport = this.aCompletedImportsData[idx]

        this.aTableData.push(oImport)
      }

      this.buildTableColumns()
    },
    buildTableColumns () {
      const aFields = [
        { key: 'created_at', label: this.$t(this.translationPath + 'columns.date'), sortable: true, class: 'text-left td-date' },
        { key: 'title', label: this.$t(this.translationPath + 'columns.title'), sortable: true, class: 'text-left td-title' },
        { key: 'account_title', label: this.$t(this.translationPath + 'columns.account_title'), sortable: true, class: 'text-left td-account_title' },
        { key: 'transactions_count', label: this.$t(this.translationPath + 'columns.count_transactions'), sortable: true, class: 'text-right td-count_transactions', formatter: (value) => `${value} st` }
      ]
      this.aTableColumns = aFields
    }
  },
  watch: {
    startDate: {
      handler () {
        this.loadData()
      },
      immediate: true,
      deep: true
    },
    endDate: {
      handler () {
        this.loadData()
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss">
.component-transaction-view-predefined-selections-imports-table{

}
</style>
