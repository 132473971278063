<template>
  <div class="component-transactions-view">
    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>

      <!-- START BREADCRUMB, TITLE, TABS WRAPPER -->
      <b-container class="main-gradient-header-wrapper pb-5">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="4" md="6" lg="7" xl="8" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'title') }}</h1>
            </b-col>
            <b-col cols="12" sm="8" md="6" lg="5" xl="4" class="pull-left pr-0 pl-0 pl-md-2">
              <period-selector
                v-if="isPeriodSelectorShown"
                default-period-type="rolling-3-months"
                :show-resolution="false"
                :show-period-option-full-period="true"
                :show-last-months="true"
                :show-last-years="true"
                :default-start-date="aFilterParams.start_date ? aFilterParams.start_date : null"
                :default-end-date="aFilterParams.end_date ? aFilterParams.end_date : null"
                @period-selected="onPeriodChange"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-2" no-gutters>
          <b-col no-gutters>
            <transactions-view-submenu class="overview" :active="activeTab" @tab-switched="onTabSwitched"></transactions-view-submenu>
          </b-col>
        </b-row>

      </b-container>
      <!-- END BREADCRUMB, TITLE, TABS WRAPPER -->

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0 transaction-view-wrapper">
      <!-- Table components here -->
      <transaction-view-transactions-table v-if="activeTab === 'transactions'" :activeTab="activeTab" :apiData="apiData" :isLoaded="!busyState" :aFilterParams="aFilterParams" :paginationCurrentPage="iPaginationCurrentPage" @change="loadData(true)" @emitQuickFilterUpdate="handleQuickFilterEmit" @emitBadgeClick="deleteFilterParamByBadgeClose"/>
      <transaction-view-stats-tables v-if="activeTab === 'transaction_stats'" :activeTab="activeTab" :apiData="apiData" :isLoaded="!busyState" :aFilterParams="aFilterParams"></transaction-view-stats-tables>
      <transaction-view-imports-table v-if="activeTab === 'completed_imports'" :activeTab="activeTab" :apiData="apiData" :isLoaded="!busyState" :aFilterParams="aFilterParams" @emitQuickFilterUpdate="handleQuickFilterEmit" @changeTab="onTabSwitched"></transaction-view-imports-table>

      <transaction-view-predefined-selections v-if="activeTab === 'predefined_selections'" :activeTab="activeTab" :isLoaded="!busyState" :aFilterParams="aFilterParams" @changeTab="onTabSwitched"></transaction-view-predefined-selections>
    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import titleMixins from '@/mixins/title'
import TransactionsViewMixin from './inc/TransactionsViewMixin'
import PeriodSelector from '@/components/common/PeriodSelector'
import TransactionsViewSubmenu from './inc/TransactionsViewSubmenu'
import TransactionViewTransactionsTable from './tables/TransactionViewTransactionsTable'
import TransactionViewStatsTables from './tables/TransactionViewStatsTables'
import TransactionViewImportsTable from './tables/PredefinedSelections/ImportsTable'
import TransactionViewPredefinedSelections from './views/TransactionViewPredefinedSelections'
import moment from 'moment'

export default {
  name: 'TransactionsView',
  mixins: [titleMixins, TransactionsViewMixin],
  components: {
    PeriodSelector,
    TransactionsViewSubmenu,
    TransactionViewTransactionsTable,
    TransactionViewStatsTables,
    TransactionViewImportsTable,
    TransactionViewPredefinedSelections
  },
  data () {
    return {
      validRouteParams: ['transactions', 'transaction_stats', 'predefined_selections'],
      activeTab: 'transactions',
      apiData: null,
      translationPath: 'transactions.view-v2.',
      busyState: true,
      bHasAPIDataBeenFetchedOnce: false,
      iPaginationCurrentPage: '1',
      /**
       * Period selector should be shown after all initial params are compiled.
       */
      isPeriodSelectorShown: false,
      /**
       * Same params that can be sent to API and received as GET-params
       */
      aFilterParams: {
        start_date: null,
        end_date: null,
        transaction_subtype: null,
        account_id: null,
        title: null,
        amount_min: null,
        amount_max: null,
        filter_accounts: null,
        import_id: null,
        source_account_id: null,
        account_group_id: null,
        description: null
      }
    }
  },
  created () {
    this.getFilterParamsFromRouteParams()
    if (this.$route.query.current_page !== undefined) {
      this.iPaginationCurrentPage = this.$route.query.current_page
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      return [
        { text: this.$t('common.header.header_menu.transactions.transactions'), to: '/transactions/import/all' },
        { text: '', active: true }
      ]
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    },
    currency () {
      return this.currentCOA.currency_iso ? this.currentCOA.currency_iso : ''
    },
    currencySymbol () {
      return this.currentCOA.currency ? this.currentCOA.currency : ''
    }
  },
  methods: {
    loadingDataToast () {
      this.$bvToast.toast(this.$t(this.translationPath + 'toasts.loading.description').toString(), {
        title: this.$t(this.translationPath + 'toasts.loading.title').toString(),
        variant: 'info',
        solid: true
      })
    },
    /**
     * On page load - read params from route
     * E.g. /transactions/view/transactions?start_date=XXXX&end_date=YYYY
     */
    getFilterParamsFromRouteParams () {
      for (const idx in this.$route.query) {
        if (this.aFilterParams[idx] !== undefined) {
          this.aFilterParams[idx] = this.$route.query[idx]
        }
      }

      if (this.aFilterParams.start_date && !this.aFilterParams.end_date) {
        this.aFilterParams.end_date = moment().format('YYYY-MM-DD')
      } else if (!this.aFilterParams.start_date && this.aFilterParams.end_date) {
        this.aFilterParams.start_date = moment(this.aFilterParams.end_date).subtract(1, 'months').add(1, 'days').format('YYYY-MM-DD')
      }

      this.isPeriodSelectorShown = true
    },
    deleteFilterParamByBadgeClose (sParam) {
      switch (sParam) {
        case 'amount' :
          this.aFilterParams.amount_min = null
          this.aFilterParams.amount_max = null
          break
        default:
          this.aFilterParams[sParam] = null
      }
      this.loadData()
    },
    /**
     * Load transaction data from API endpoint
     */
    async loadData (inBackground = false) {
      this.busyState = !inBackground
      const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/transactions/v2?with_tags=1' + this.createParamStringFromFilterParams(this.aFilterParams)

      await axios({ method: 'get', url: sApiEndpoint })
        .then((response) => {
          this.apiData = response.data.data
          return true
        })
        .catch((error) => {
          console.error(error)
          return false
        })
      this.busyState = false
    },
    onTabSwitched (newtab) {
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab) {
        this.$router.push({ params: { tab: newtab }, query: this.buildRouteQueryFromFilterParams() })
      }
    },
    resetFilterParams () {
      for (const idx in this.aFilterParams) {
        if (this.aFilterParams[idx] !== null && this.aFilterParams[idx] !== '') {
          this.aFilterParams[idx] = null
        }
      }
    },
    buildRouteQueryFromFilterParams () {
      const oQuery = {}
      for (const idx in this.aFilterParams) {
        if (this.aFilterParams[idx] !== null && this.aFilterParams[idx] !== '') {
          oQuery[idx] = this.aFilterParams[idx]
        }
      }
      return oQuery
    },
    /**
     * Handle emitted data from period_selector
     */
    onPeriodChange (data) {
      // Set start_date and end_date at start ONLY if no other params has been set. From now on, load date on period_selector change
      if (this.bHasAPIDataBeenFetchedOnce || this.createParamStringFromFilterParams(this.aFilterParams).length === 0) {
        this.aFilterParams.start_date = data.sDateStart
        this.aFilterParams.end_date = data.sDateEnd
      }

      this.loadData()
      this.bHasAPIDataBeenFetchedOnce = true
    },
    handleQuickFilterEmit (data) {
      if (this.checkIfNotNullAndUndefined(data.title)) { this.aFilterParams.title = data.title }
      if (this.checkIfNotNullAndUndefined(data.transaction_subtype)) { this.aFilterParams.transaction_subtype = data.transaction_subtype }
      if (this.checkIfNotNullAndUndefined(data.amount_min) || data.amount_min === null) { this.aFilterParams.amount_min = data.amount_min }
      if (this.checkIfNotNullAndUndefined(data.amount_max) || data.amount_max === null) { this.aFilterParams.amount_max = data.amount_max }
      if (this.checkIfNotNullAndUndefined(data.account_id) || data.account_id === null) { this.aFilterParams.account_id = data.account_id }
      if (this.checkIfNotNullAndUndefined(data.source_account_id) || data.source_account_id === null) { this.aFilterParams.source_account_id = data.source_account_id }
      if (this.checkIfNotNullAndUndefined(data.import_id)) {
        this.aFilterParams.start_date = null
        this.aFilterParams.end_date = null
        this.aFilterParams.import_id = data.import_id
      }
      if (this.checkIfNotNullAndUndefined(data.description)) {
        this.aFilterParams.description = data.description
      }

      this.loadData()
    },
    checkIfNotNullAndUndefined (value) {
      return value !== '' && value !== null && value !== undefined
    }
  },
  mounted () {
    if (this.validRouteParams.indexOf(this.$route.params.tab) !== -1) {
      this.onTabSwitched(this.$route.params.tab)
    }
    this.loadingDataToast()
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    '$route.params.tab' () {
      if (this.validRouteParams.indexOf(this.$route.params.tab) !== -1) {
        this.onTabSwitched(this.$route.params.tab)
      }
    },
    // Update when we push query data from other pages
    '$route.query' () {
      let bUpdate = false

      for (const idx in this.$route.query) {
        if (this.$route.query[idx] !== this.aFilterParams[idx]) {
          this.aFilterParams[idx] = this.$route.query[idx]
          bUpdate = true
        }
      }

      if (bUpdate) {
        this.loadData()
      }
    },
    /**
     * When filter params are changed - we modify route query string.
     * As filter params placed in URL, user is able to bookmark filtered page or
     * visit it with browser navigation buttons (back/next).
     */
    aFilterParams: {
      deep: true,
      immediate: false, // Important
      handler () {
        const query = {}
        for (const paramName in this.aFilterParams) {
          if (this.aFilterParams[paramName]) {
            // Don't add if current_page is 1
            if (!(paramName === 'current_page' && this.aFilterParams[paramName] === 1)) {
              query[paramName] = this.aFilterParams[paramName]
            }
          }
        }

        /**
         * To avoid duplicated navigation error we should check current params with desired params.
         */
        const iNumberQueryParams = Object.keys(this.$route.query).length // Object.keys(this.$route.query).length || (!Object.keys(this.$route.query).length && !Object.keys(query).length)
        let bUpdateQuery = true

        if (iNumberQueryParams === Object.keys(query).length) {
          bUpdateQuery = false
          for (const paramName in this.$route.query) {
            if (this.$route.query[paramName] !== query[paramName]) {
              bUpdateQuery = true
              break
            }
          }
        }

        if (bUpdateQuery) {
          this.$router.push({ name: this.$route.name, query: query })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.component-transactions-view{
  width: 100%;

  .transaction-view-wrapper{
    margin-top:-75px;
  }
}
</style>
